export default {
  changeInSchool(formName) {
    if (this[formName].inSchool === 0) {
      this.$refs[formName].clearValidate('schoolYearId');
    }
  },
  GetSchoolList() {
    return this.SchoolFunc.GetSchool().then((res) => {
      if (res.data.statusCode === 0) {
        this.SchoolOption = res.data.data;
      }
    });
  },
  GetSchoolYearList() {
    return this.SchoolFunc.GetSchoolYear().then((res) => {
      if (res.data.statusCode === 0) {
        this.SchoolYearOption = res.data.data;
      }
    });
  },
  SetSchoolDept(formName) {
    this[formName].schoolDeptId = '';

    const postData = {
      schoolId: this[formName].schoolId,
      type: 99,
    };
    this.SchoolFunc.GetSchoolDept(postData).then((res) => {
      if (res.data.statusCode === 0) {
        this.SchoolDeptOption = res.data.data;
      }
    });
  },
};
