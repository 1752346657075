import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;
/** Base api */

/*  發送驗證碼(通用) */
export const SendVerification = (data) => baseAxios.post('/FrontEnd/Verification/SendVerification', data);

/*  發送驗證碼(會員專用) */
export const MemberSendVerification = (data) => baseAxios.post('/FrontEnd/Verification/MemberSendVerification', data);
