<template>
  <!-- 主要內容 -->
  <section class="container register-bg pb-8 ">
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">會員註冊</h2>
        <el-form
          style="width: 100%;"
          :model="RegisterForm"
          label-position="top"
          :rules="RegisterRules"
          ref="RegisterForm"
          class="cs-form"
          data-style="2"
          @submit.prevent.native
        >
          <!-- 信箱 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberMail">
              會員信箱
            </label>
            <el-form-item prop="memberMail" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="信箱"
                type="text"
                id="memberMail"
                v-model="RegisterForm.memberMail"
              />
            </el-form-item>
            <small id="emailHelp" class="col-12 col-md-9 offset-md-3 mt-2 form-text text-danger">
              *註冊信箱為您未來的登入帳號，註冊後將不可修改
            </small>
          </div>

          <!-- 信箱認證 -->
          <div class="form-group row ">
            <label
              class="col-12 col-md-3 col-form-label necessary"
              for="mailVerificationCaptchaCode"
            >
              信箱認證碼
            </label>
            <el-form-item prop="mailVerificationCaptchaCode" class="col-12 col-md-5 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="信箱認證碼"
                type="text"
                id="mailVerificationCaptchaCode"
                v-model="RegisterForm.mailVerificationCaptchaCode"
              />
            </el-form-item>
            <div class="col-12 col-md-4 mb-2">
              <button
                type="button"
                class="btn btn-hover-primary text-white border-radius-l w-100"
                :disabled="mailTimerText !== '發送認證碼'"
                @click="GetVerification(1)"
              >
                <span class="small">{{ mailTimerText }}</span>
              </button>
            </div>
          </div>

          <!-- 密碼 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberPassword">
              登入密碼
            </label>
            <el-form-item prop="memberPassword" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="密碼"
                type="password"
                id="memberPassword"
                v-model="RegisterForm.memberPassword"
              />
            </el-form-item>
          </div>
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberConfirmPassword">
              確認密碼
            </label>
            <el-form-item prop="memberConfirmPassword" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="確認密碼"
                type="password"
                id="memberConfirmPassword"
                v-model="RegisterForm.memberConfirmPassword"
              />
            </el-form-item>
          </div>
          <!-- 姓名 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberName">
              會員姓名
            </label>
            <el-form-item prop="memberName" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="姓名"
                type="text"
                id="memberName"
                v-model="RegisterForm.memberName"
              />
            </el-form-item>
          </div>

          <!-- 手機 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberPhone">
              手機號碼
            </label>
            <el-form-item prop="memberPhone" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="手機號碼"
                type="text"
                id="memberPhone"
                v-model="RegisterForm.memberPhone"
              />
            </el-form-item>
          </div>

          <!-- 手機認證 -->
          <div class="form-group row ">
            <label
              class="col-12 col-md-3 col-form-label necessary"
              for="phoneVerificationCaptchaCode"
            >
              手機認證碼
            </label>
            <el-form-item prop="phoneVerificationCaptchaCode" class="col-12 col-md-5 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="手機認證碼"
                type="text"
                id="phoneVerificationCaptchaCode"
                v-model="RegisterForm.phoneVerificationCaptchaCode"
              />
            </el-form-item>
            <div class="col-12 col-md-4 mb-2">
              <button
                type="button"
                class="btn btn-hover-primary text-white border-radius-l w-100"
                :disabled="phoneTimerText !== '發送認證碼'"
                @click="GetVerification(2)"
              >
                <span class="small">{{ phoneTimerText }}</span>
              </button>
            </div>
          </div>

          <!-- 收件人住址 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberZipCode">
              收件人住址
            </label>

            <el-form-item prop="memberZipCode" class="col-12 col-md-2 mb-4">
              <el-input
                placeholder="郵遞區號"
                v-model="RegisterForm.memberZipCode"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item prop="memberCounty" class="col-12 col-md-3 mb-4">
              <el-select
                :style="`display: block;`"
                v-model="RegisterForm.memberCounty"
                @change="SetCityList(1)"
                placeholder="請選擇縣市"
              >
                <el-option
                  v-for="item in CountyOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="memberCity" class="col-12 col-md-3 mb-4">
              <el-select
                :style="`display: block;`"
                v-model="RegisterForm.memberCity"
                @change="SetZipCode()"
                placeholder="請選擇地區"
              >
                <el-option
                  v-for="item in CityOption"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="memberAddress" class="col-12 col-md-9 offset-md-3 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="地址"
                type="text"
                id="memberAddress"
                v-model="RegisterForm.memberAddress"
              />
            </el-form-item>
          </div>

          <!-- 是否為在校生 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary">
              是否為在校生
            </label>
            <el-form-item
              prop="inSchool"
              class="col-12 col-md-9 mb-2"
            >
              <el-radio-group
                v-model="RegisterForm.inSchool"
              >
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <!-- 目前最高學歷 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary">
              目前最高學歷
            </label>

            <el-form-item
              prop="schoolId"
              class="col-12 col-md-3 mb-4"
            >
              <el-select
                :style="`display: block;`"
                v-model="RegisterForm.schoolId"
                @change="SetSchoolDept('RegisterForm')"
                filterable
                placeholder="請選擇學校"
              >
                <el-option
                  v-for="item in SchoolOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              prop="schoolDeptId"
              class="col-12 col-md-3 mb-4"
            >
              <el-select
                :style="`display: block;`"
                v-model="RegisterForm.schoolDeptId"
                @change="SetZipCode()"
                filterable
                placeholder="請選擇系所"
              >
                <el-option
                  v-for="item in SchoolDeptOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="RegisterForm.inSchool"
              prop="schoolYearId"
              class="col-12 col-md-3 mb-4"
            >
              <el-select
                :style="`display: block;`"
                v-model="RegisterForm.schoolYearId"
                @change="SetZipCode()"
                placeholder="請選擇年級"
              >
                <el-option
                  v-for="item in SchoolYearOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <!-- 驗證碼 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberVerification"
              >驗證碼</label
            >
            <el-form-item prop="captchaCode" class="col-7 col-md-5">
              <el-input
                :class="`el-input__input`"
                placeholder="驗證碼"
                type="text"
                id="captchaCode"
                v-model="RegisterForm.captchaCode"
              />
            </el-form-item>
            <!-- d-flex align-items-center -->
            <div class="col-4 col-md-4">
              <img class="img-verify" :src="`data:image/png;base64,${CodeImg}`" alt="驗證碼" />
            </div>
          </div>
          <!-- 同意服務條款 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="form-check mr-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="agree"
                v-model="RegisterForm.IsCheck"
                :true-value="1"
                :false-value="0"
              />
              <label class="form-check-label text-accent" for="agree">我已詳閱並同意</label>
            </div>
            <a href="/novices/policy" class="" target="_blank">服務條款</a>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="RegistMember"
              >
                <span class="small">確認送出</span>
              </button>
            </div>
          </div>
          <div class="text-center">
            <span>已經是會員？</span>
            <router-link class="link-hover-accent pr-4" to="/login">
              會員登入
            </router-link>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import {
  RegistMember,
  GetSchool,
  GetSchoolDept,
  GetSchoolYear,
} from '@/api/MemberApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';
import { SendVerification } from '@/api/BaseApi';
import { GetCountyList, GetCityList } from '@/lib/zipcode';
import SchoolFunc from '@/lib/school';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      IsCheck: 0, // 是否已詳閱隱私權條款
      CodeImg: '', // 圖形驗證碼使用的data
      RegisterForm: {
        // 註冊的 model data
        memberId: 0,
        memberAccount: '',
        memberPassword: '',
        memberConfirmPassword: '',
        memberName: '',
        memberMail: '',
        memberPhone: '',
        memberZipCode: '',
        memberCounty: '',
        memberCity: '',
        memberAddress: '',
        isReceive: 1,
        status: 1,
        code: '',
        captchaCode: '',
        // 信箱認證
        mailVerificationCaptchaCode: '',
        mailVerificationGuid: '',
        // 手機認證
        phoneVerificationCaptchaCode: '',
        phoneVerificationGuid: '',
        // 學校資訊
        inSchool: '',
        schoolId: '',
        schoolDeptId: '',
        schoolYearId: '',
      },
      RegisterRules: {
        // 檢查輸入的值規則
        memberAccount: [
          { required: true, message: '請輸入帳號', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
        ],
        memberPassword: [
          { required: true, message: '請輸入密碼', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
          {
            pattern: /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/,
            message: '請輸入英數字',
            trigger: 'blur',
          },
        ],
        memberConfirmPassword: [
          { required: true, message: '請輸入確認密碼', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
          {
            pattern: /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/,
            message: '請輸入英數字',
            trigger: 'blur',
          },
        ],
        memberName: [{ required: true, message: '請輸入名稱', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FFF\u3400-\u4DBF\u{20000}-\u{2A6DF}\u{2A700}-\u{2B73F}\u{2B740}-\u{2B81F}\u{2B820}-\u{2CEAF}\u{2CEB0}-\u{2EBEF}]*$/u, message: '僅可輸入中文', trigger: 'blur' },
        ],
        memberMail: [
          { required: true, message: '請輸入信箱', trigger: 'blur' },
          { type: 'email', message: '請輸入正確的信箱格式', trigger: ['blur', 'change'] },
        ],
        memberPhone: [
          { required: true, message: '請輸入手機號碼', trigger: 'blur' },
          { min: 10, trigger: ['blur', 'change'], message: '手機號碼必須為10碼' },
          {
            pattern: /^09[0-9]{8}$/,
            message: '請輸入正確的手機號碼',
            trigger: 'blur',
          },
        ],
        memberZipCode: [{ required: true, message: '請輸入郵遞區號', trigger: 'blur' }],
        memberCounty: [{ required: true, message: '請選擇縣市', trigger: 'change' }],
        memberCity: [{ required: true, message: '請選擇地區', trigger: 'change' }],
        memberAddress: [{ required: true, message: '請輸入地址', trigger: 'blur' }],
        captchaCode: [{ required: true, message: '請輸入驗證碼', trigger: 'blur' }],
        mailVerificationCaptchaCode: [
          { required: true, message: '請輸入信箱驗證碼', trigger: 'blur' },
        ],
        phoneVerificationCaptchaCode: [
          { required: true, message: '請輸入手機驗證碼', trigger: 'blur' },
        ],
        inSchool: [{ required: true, message: '請選擇是否為在校生', trigger: ['blur', 'change'] }],
        schoolId: [{ required: true, message: '請選擇學校', trigger: ['blur', 'change'] }],
        schoolDeptId: [{ required: true, message: '請選擇系所', trigger: ['blur', 'change'] }],
        schoolYearId: [{ required: true, message: '請選擇年級', trigger: ['blur', 'change'] }],
      },
      mailTimer: '',
      mailTimerText: '發送認證碼',
      phoneTimer: '',
      phoneTimerText: '發送認證碼',
      CountyOption: [],
      CityOption: [],
      SchoolOption: [],
      SchoolDeptOption: [],
      SchoolYearOption: [],
      SchoolFunc: {
        GetSchool,
        GetSchoolYear,
        GetSchoolDept,
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'RegisterForm.inSchool': function () {
      if (this.RegisterForm.inSchool === 0) {
        this.changeInSchool('RegisterForm');
      }
    },
  },
  created() {
    // 一開始執行的地方
    // 呼叫圖形驗證碼
    this.GetCaptchaCode();
    this.SetCountyList();
    this.SetCityList(0);

    this.GetSchoolList();
    this.GetSchoolYearList();
  },
  beforeRouteLeave(to, form, next) {
    this.ClearTimer('mail');
    this.ClearTimer('phone');
    next();
  },
  methods: {
    ...SchoolFunc,
    handleReplaceSpace() {
      this.RegisterForm.memberAccount = this.RegisterForm.memberAccount.replace(/\s+/g, '');
    },
    SetCountyList() {
      this.CountyOption = GetCountyList();
    },
    SetCityList(clear) {
      this.CityOption = GetCityList(this.RegisterForm.memberCounty);
      if (clear === 1) {
        this.RegisterForm.memberCity = '';
        this.RegisterForm.memberZipCode = '';
      } else {
        const data = this.CityOption.filter((item) => this.RegisterForm.memberCity === item.label);
        if (!data[0]) {
          this.RegisterForm.memberCity = '';
          this.RegisterForm.memberZipCode = '';
        }
      }
    },
    SetZipCode() {
      const data = this.CityOption.filter((item) => this.RegisterForm.memberCity === item.label);
      if (data[0]) {
        this.RegisterForm.memberZipCode = data[0].value;
      } else {
        this.RegisterForm.memberZipCode = '';
      }
    },
    // 你的 js function
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.RegisterForm.code = response.data.data.code;
          GetCaptchaBase64(this.RegisterForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    RegistMember() {
      // 註冊按鈕
      this.$refs.RegisterForm.validate((valid) => {
        if (valid) {
          if (this.RegisterForm.IsCheck === 1) {
            if (this.RegisterForm.memberPassword === this.RegisterForm.memberConfirmPassword) {
              const form = this.RegisterForm;
              const postData = this._.cloneDeep(form);

              postData.schoolId = form.schoolId === '' ? 0 : form.schoolId;
              postData.schoolDeptId = form.schoolDeptId === '' ? 0 : form.schoolDeptId;
              postData.schoolYearId = form.schoolYearId === '' ? null : form.schoolYearId;

              RegistMember(this.RegisterForm).then((response) => {
                if (response.data.statusCode === 0) {
                  this.$message({
                    showClose: true,
                    type: 'success',
                    message: '註冊成功',
                  });
                  this.$router.push('/Login');
                } else {
                  this.GetCaptchaCode();
                }
              });
            } else {
              this.$message({
                showClose: true,
                type: 'error',
                message: '密碼與確認密碼不相同',
              });
            }
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: '請勾選【我已詳閱並同意】',
            });
          }
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
    GetVerification(type) {
      let boxName = 'memberMail';
      let checkValue = this.RegisterForm.memberMail;
      let typeName = 'mail';
      if (type === 2) {
        typeName = 'phone';
        checkValue = this.RegisterForm.memberPhone;
        boxName = 'memberPhone';
      }

      this.$refs.RegisterForm.validateField(boxName, (error) => {
        if (!error) {
          const postData = {
            VerificationType: type,
            VerificationData: checkValue,
          };
          SendVerification(postData).then((response) => {
            if (response.data.statusCode === 0) {
              this.StatrTimer(typeName);
              this.RegisterForm[`${typeName}VerificationGuid`] = response.data.data.verificationGuid;
            }
          });
        }
      });
    },
    StatrTimer(type) {
      const vm = this;
      const end = vm.$moment(new Date()).add(1, 'minutes');
      this.TiemrFunc(type, end);
      this[`${type}Timer`] = setInterval(() => this.TiemrFunc(type, end), 1000);
    },
    ClearTimer(type) {
      clearInterval(this[`${type}Timer`]);
    },
    TiemrFunc(type, end) {
      const now = new Date();
      const timeLeft = this.$moment(end).diff(now);
      const timeLeftFormat = this.$moment(timeLeft).format('mm:ss');
      if (timeLeft <= 0) {
        this.ClearTimer(type);
        this[`${type}TimerText`] = '發送認證碼';
      } else {
        this[`${type}TimerText`] = `${timeLeftFormat}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-form-item {
  &:not(.is-error) {
    margin-bottom: 0;
  }
}
::v-deep .el-radio {
  &__inner {
    border: 1px solid #174e5f;
  }

  &__label {
    color: #174e5f;
  }

  &__input {
    transform: scale(1.2);

    &.is-checked  {
      .el-radio__inner {
          border-color: #174e5f;
          background: #174e5f;
      }

      + .el-radio__label {
        color: #174e5f;
      }
    }
  }
}
</style>
